const normalizeSrc = (src: string | unknown) => {
  if (typeof src === 'string') return src[0] === '/' ? src.slice(1) : src
  return src
}

export declare type ImageLoaderProps = {
  src: string
  width: number
  quality?: number
}
export declare type ImageLoader = (resolverProps: ImageLoaderProps) => string

export const cloudflareLoader: ImageLoader = ({ src, width, quality, ...rest }) => {
  if (src.includes('.svg')) {
    // Cloudflare Image service doesn't support SVGs
    return undefined
  }

  const params = [`width=${width}`, 'gravity=auto', 'fit=cover', 'metadata=none', 'format=auto']
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')

  if (typeof window !== 'undefined') {
    if (src.startsWith('/') && window.location.origin != 'https://airheart.com') {
      src = `${window.location.origin}${src}`
    }
  }

  return `https://airheart.com/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

export function cdnSrc(src: string, width: number, quality?: number) {
  const loader = cloudflareLoader({ src: src as string, width, quality })
  if (loader != null) return loader
  return src as string
}
